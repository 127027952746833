









































































































































































































































































































































































































































































































$color: #fff;
$color_dark: var(--color-text-primary);
$color_grey: var(--color-text-secondary);
$color_red: #B00020;
$color_green: green;
$back_dark: #151929;
$back_light: #f0f0f0;
$gradient_colors_list: #fafafa, #eaeaea;

.broker-simulator-layout {
  padding: 0 60px;
}

#broker-simulator {
  background-color: var(--color-background);
}

html{
  overflow-y: scroll;
}

h2 {
  color: $color_dark;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: .15px;
  margin: 0;
  padding: 80px 0 34px;
}

.text-grey-uppercase {
  color: $color_grey;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 15px;}

.historic-data-leyend {
  margin: 20px 0;
  color: $color_dark;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-size: 14px;}



.border-bottom-stoploss {
  border-top: 1px solid #d0d0d0;
  padding-top: 16px;}

.color-green {
  color: $color_green;}

.mdc-data-table__cell.align-left {
  text-align: left;}

.relation-orders-wrapper {
  display: block;
  margin-bottom: 0;
  .mdc-switch-wrapper {
    width: 100%;
    margin-bottom: 15px;
  }
}

.broker-history-not-correct {
  background: #ffdada;}

.select-container-size,
.input-container-size {
  margin-bottom: 22px;}
.button {
  margin-top: 50px;
  margin-bottom: 20px;}
.data-table {
  width: 100%;}

@media (max-width: 599px){
  .mdc-layout-grid__cell {
    margin: 0;}
  .select-container-size {
    flex: 0 0 48%;
    max-width: 100%;}
  .input-container-size {
    flex: 0 0 48%;
    max-width: 100%;}  
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid__cell--span-6.left {
    grid-column-end: span 8;
    margin: 0;}
  .mdc-layout-grid__cell--span-6.right {
    grid-column-end: span 8;
    margin: 0;}
  .select-container-size {
    flex: 0 0 48%;
    max-width: 100%;}
  .input-container-size {
    flex: 0 0 48%;
    max-width: 100%;}
  .row {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    justify-content: space-between;}
  .relation-orders-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    .mdc-switch-wrapper {
      width: 48%;
      font-size: 14px;
      line-height: 26px;
      .take-profit-order,
      .stop-loss-order,
      .oco-order {
        vertical-align: middle;
      }
      label {
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
}
@media (min-width: 840px){
  .mdc-layout-grid__cell--span-6.left {
    margin: 0 36px 0 0;}
  .mdc-layout-grid__cell--span-6.right {
    margin-right: 36px;}
  .select-container-size {
    flex: 0 0 48%;
    max-width: 100%;}
  .input-container-size {
    flex: 0 0 48%;
    max-width: 100%;}
  .row {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    justify-content: space-between;}
  
  .relation-orders-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    .mdc-switch-wrapper {
      width: 48%;
      font-size: 14px;
      line-height: 26px;
      .take-profit-order,
      .stop-loss-order,
      .oco-order {
        vertical-align: middle;
      }
      label {
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
  
}

.iframe-container{
  height:100% !important;
}

.container-image{
  /* Aplica un margen automático para centrar el div horizontalmente */
  margin: 0 auto;
  /* Ajusta el ancho máximo del contenedor */
  max-width: 800px;
  max-height: auto;
  
}



.container-image img{
  max-width: 500px;   
  max-height: 800px;
  margin: 20px;
  border-radius: 5px;
}

@media screen and (min-width: 768px) {
  .container-image img {
    width: 500px;
  }
}

.text-center {
  text-align: center;
  margin: 20px;
}

.container-image p {    
  text-align: justify;
}

.login-button{
  color: white;
  background-color: #00add8;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
  border: none;
  outline: none;
  width: 150px;
  margin-top: 20px;
  font-size: 14px;
}
.login-button:hover{
  background-color: #00bdec;
}

  .not-allowed-broker-simulator-container{
    text-align: center;
    margin-top: 50px;
  }

  .link-to-store{
    color: #35add8 !important;
  }

  .not-allowed-broker-simulator-title{
    color: #FF9B00;
    font-weight: bold;
  }
